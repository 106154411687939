<template>
  <v-card>
    <v-container fluid>
      <v-row>
        <v-col class="d-flex child-flex">
          <v-img
            class="white--text align-end"
            max-width="100"
            contain
            :src="imgSource"
            @click="visitTarget(target)"
          >
          </v-img>
        </v-col>
      </v-row>
    </v-container>
    <v-card-title @click="visitTarget(target)">{{ target.name }}</v-card-title>
    <v-card-subtitle @click="visitTarget(target)">{{
      target.client.name
    }}</v-card-subtitle>

    <v-card-actions>
      <v-btn text @click="visitTarget(target)">
        <v-icon right dark>{{ buildIcon }}</v-icon>View
      </v-btn>
      <v-spacer />

      <v-tooltip bottom v-if="show_favorites !== 'yes'">
        <template v-slot:activator="{ on }">
          <v-btn icon v-on="on" @click="editTarget">
            <v-icon>mdi-pencil</v-icon>
          </v-btn>
        </template>
        <span>Edit</span>
      </v-tooltip>
      <v-tooltip bottom v-if="show_favorites !== 'yes'">
        <template v-slot:activator="{ on }">
          <v-btn icon v-on="on" @click="promptForDelete">
            <v-icon>mdi-delete</v-icon>
          </v-btn>
        </template>
        <span>Delete {{ target.name }}</span>
      </v-tooltip>
    </v-card-actions>
  </v-card>
</template>

<script>
export default {
  name: "OutputTargetCard",
  props: {
    target: {
      type: Object,
      required: true
    },
    show_favorites: {
      type: String,
      default: "no"
    }
  },
  data: () => ({}),
  computed: {
    docCount: function() {
      return 5;
    },
    userCount: function() {
      return 0;
      //return this.target.users.length || 0;
    },
    imgSource: function() {
      return (
        process.env.VUE_APP_API_ENDPOINT +
        "assets/target/img/" +
        this.target.img
      );
    },
    buildIcon: function() {
      if (this.target.build_type === "web") {
        return "mdi-earth-arrow-right";
      } else {
        return "mdi-file-pdf-box";
      }
    }
  },
  methods: {
    loadForm(target) {
      this.$emit("loadTargetForm", target);
    },
    promptForDelete() {
      this.$emit("delete-clicked", this.target);
    },
    editTarget() {
      this.$emit("edit-clicked", this.target);
    },
    visitTarget(target) {
      window.open(
        process.env.VUE_APP_API_ENDPOINT +
          "content/" +
          target.build_type +
          "/" +
          target.output_pool.id +
          "/" +
          target.target_url,
        "_blank"
      );
    }
  }
};
</script>
