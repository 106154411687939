export default ({ adminUserGuard, userGuard }) => [
  // Guest routes.
  {
    path: "/unauthorized",
    name: "unauthorized",
    meta: { layout: "simple" },

    component: () =>
      import(/* webpackChunkName: "unauth" */ "@/views/auth/Unauthorized.vue")
  },
  {
    path: "/verify",
    name: "verify",
    meta: { layout: "simple" },

    component: () =>
      import(/* webpackChunkName: "unauth" */ "@/views/auth/VerifyEmail.vue")
  },
  {
    path: "/forgotpassword",
    name: "forgotpassword",
    component: require("@/views/auth/ForgotPassword.vue").default,
    meta: { layout: "simple" }
  },
  {
    path: "/password/reset/:token",
    name: "resetpassword",
    props: true,
    component: require("@/views/auth/ResetPassword.vue").default,
    meta: { layout: "simple" }
  },
  {
    path: "/login",
    name: "login",
    component: require("@/views/auth/Login.vue").default,
    meta: { layout: "simple" }
  },
  ...adminUserGuard([
    {
      path: "/",
      name: "dashboard",
      meta: { layout: "app" },
      component: require("@/views/dashboard/Dashboard.vue").default
    },
    {
      path: "/profile",
      name: "profile",
      meta: { layout: "app" },
      component: require("@/views/profile/Profile.vue").default
    },
    {
      path: "/tickets",
      name: "tickets",
      meta: { layout: "app" },

      component: () =>
        import(/* webpackChunkName: "tickets" */ "@/views/tickets/Tickets.vue")
    },
    {
      path: "/clients",
      name: "clients",
      meta: { layout: "app" },

      component: () =>
        import(/* webpackChunkName: "clients" */ "@/views/clients/Clients.vue")
    },
    {
      path: "/projects",
      name: "projects",
      meta: { layout: "app" },

      component: () =>
        import(
          /* webpackChunkName: "projects" */ "@/views/projects/Projects.vue"
        )
    },
    {
      path: "/projects/:client_id",
      props: true,
      name: "client-projects",
      meta: { layout: "app" },

      component: () =>
        import(
          /* webpackChunkName: "projects" */ "@/views/projects/Projects.vue"
        )
    },
    {
      path: "/projects/:client_id/:project_id",
      props: true,
      name: "client-projects",
      meta: { layout: "app" },

      component: () =>
        import(
          /* webpackChunkName: "projects" */ "@/views/projects/Projects.vue"
        )
    },

    {
      path: "/reports",
      name: "reports",
      meta: { layout: "app" },

      component: () =>
        import(/* webpackChunkName: "reports" */ "@/views/reports/Reports.vue")
    },
    {
      path: "/reports/:client_id",
      props: true,
      name: "client-reports",
      meta: { layout: "app" },

      component: () =>
        import(/* webpackChunkName: "reports" */ "@/views/reports/Reports.vue")
    },
    {
      path: "/reports/:client_id/:report_id",
      props: true,
      name: "report-viewer",
      meta: { layout: "app" },

      component: () =>
        import(
          /* webpackChunkName: "reports" */ "@/views/reports/ReportViewer.vue"
        )
    },

    {
      path: "/output",
      name: "output",
      meta: { layout: "app" },

      component: () =>
        import(
          /* webpackChunkName: "output" */ "@/views/output-pool/OutputPool.vue"
        )
    },
    {
      path: "/targets",
      props: true,
      name: "output-targets",
      meta: { layout: "app" },

      component: () =>
        import(
          /* webpackChunkName: "output" */ "@/views/output-target/OutputTarget.vue"
        )
    },
    {
      path: "/targets/:output_pool_id",
      props: true,
      name: "pool-output-target",
      meta: { layout: "app" },

      component: () =>
        import(
          /* webpackChunkName: "output" */ "@/views/output-target/OutputTarget.vue"
        )
    },
    {
      path: "/output/:client_id",
      props: true,
      name: "client-output-pool",
      meta: { layout: "app" },

      component: () =>
        import(
          /* webpackChunkName: "output" */ "@/views/output-pool/OutputPool.vue"
        )
    },
    {
      path: "/output/:client_id/:project_id",
      props: true,
      name: "project-output-pool",
      meta: { layout: "app" },

      component: () =>
        import(
          /* webpackChunkName: "output" */ "@/views/output-pool/OutputPool.vue"
        )
    },
    {
      path: "/variables",
      name: "variables",
      meta: { layout: "app" },

      component: () =>
        import(
          /* webpackChunkName: "content" */ "@/views/variables/Variables.vue"
        )
    },
    {
      path: "/variables/:project_id",
      props: true,
      name: "client-variables",
      meta: { layout: "app" },

      component: () =>
        import(
          /* webpackChunkName: "content" */ "@/views/variables/Variables.vue"
        )
    },
    {
      path: "/variableset/:variable_set_id",
      props: true,
      name: "variable-set",
      meta: { layout: "app" },

      component: () =>
        import(
          /* webpackChunkName: "content" */ "@/views/variables/VariableSetView.vue"
        )
    },
    {
      path: "/users",
      name: "users",
      meta: { layout: "app" },

      component: () =>
        import(/* webpackChunkName: "users" */ "@/views/users/Users.vue")
    },
    {
      path: "/feedback",
      name: "feedback",
      meta: { layout: "app" },

      component: () =>
        import(
          /* webpackChunkName: "feedback" */ "@/views/feedback/Feedback.vue"
        )
    },
    {
      path: "/notifications",
      name: "notifications",
      meta: { layout: "app" },

      component: () =>
        import(
          /* webpackChunkName: "notifications" */ "@/views/notifications/Notifications.vue"
        )
    }
  ]),
  ...userGuard([
    {
      path: "/splash",
      name: "splash",
      component: require("@/views/user-dashboard/Splash.vue").default,
      meta: { layout: "simple" }
    },
    {
      path: "/logout",
      name: "logout",
      component: require("@/views/auth/Logout.vue").default,
      meta: { layout: "simple" }
    },
    {
      path: "/userprofile",
      name: "userprofile",
      meta: { layout: "user-app" },
      component: require("@/views/profile/Profile.vue").default
    },
    {
      path: "/userdashboard",
      name: "user-dashboard",
      meta: { layout: "user-app" },

      component: () =>
        import(
          /* webpackChunkName: "userdashboard" */ "@/views/user-dashboard/Dashboard.vue"
        )
    },
    {
      path: "/announcements",
      name: "announcements",
      meta: { layout: "user-app" },

      component: () =>
        import(
          /* webpackChunkName: "announcements" */ "@/views/news/Announcements.vue"
        )
    },
    {
      path: "/clientusers",
      name: "client-users",
      meta: { layout: "user-app" },

      component: () =>
        import(
          /* webpackChunkName: "clientusers" */ "@/views/user-dashboard/users/Users.vue"
        )
    },
    {
      path: "/manuals",
      name: "manuals",
      meta: { layout: "user-app" },

      component: () =>
        import(
          /* webpackChunkName: "manuals" */ "@/views/user-dashboard/manuals/Manuals.vue"
        )
    },
    {
      path: "/manuals/:output_pool_id",
      name: "pool-manuals",
      props: true,
      meta: { layout: "user-app" },

      component: () =>
        import(
          /* webpackChunkName: "userdashboard" */ "@/views/user-dashboard/manuals/Manuals.vue"
        )
    },
    {
      path: "/versions",
      name: "versions",
      meta: { layout: "user-app" },

      component: () =>
        import(
          /* webpackChunkName: "versions" */ "@/views/user-dashboard/versions/Versions"
        )
    },
    {
      path: "/userfeedback",
      name: "userfeedback",
      meta: { layout: "user-app" },

      component: () =>
        import(
          /* webpackChunkName: "feedback" */ "@/views/feedback/Feedback.vue"
        )
    },
    {
      path: "/help",
      name: "help",
      meta: { layout: "app" },

      component: () =>
        import(/* webpackChunkName: "help" */ "@/views/help/Help.vue")
    },
    {
      path: "/userhelp",
      name: "userhelp",
      meta: { layout: "user-app" },

      component: () =>
        import(/* webpackChunkName: "help" */ "@/views/help/Help.vue")
    },
    {
      path: "/index.html",
      name: "base",
      component: require("@/views/dashboard/Dashboard.vue").default,
      meta: { layout: "app" }
    },
    {
      path: "/reports/:client_id/:report_id/view",
      props: true,
      name: "report-viewer-client",
      meta: { layout: "user-app" },

      component: () =>
        import(
          /* webpackChunkName: "reports" */ "@/views/reports/ReportViewer.vue"
        )
    },
  ])
];
